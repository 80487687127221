.reviewUpdate {
    width: 50px;
    height: 30px;
    font: 16.5px Manrope;
    font-weight: 600;
    color: #2b3820;
    text-decoration: underline;
}

.updateButtonContainer {
    padding-top: 10px;
}