.pictureframeP {
    width: 400px;
    height: 250px;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 24px;
    margin-bottom: 20px;
}

.pictureframeP img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.pictureframeP {
    width: 400px;
    height: 250px;
    overflow: hidden;
    border-radius: 15px;
    position: relative;
}

.prevP, .nextP {
    cursor: pointer;
    position: absolute;
    margin-top: 30%;
    color: white;
    opacity: 50%;
    font-size: 18px;
    transition: 0.6s ease;
    user-select: none;
}

.prevP:hover, .nextP:hover {
    opacity: 100%;
}

.prevP {
    margin-left: 45px;
}

.nextP {
    margin-left: 350px;
}