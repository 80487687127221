.explorePage {
    background-color: bisque;
    width: 100vw;
    height: calc(100vh - 61px);
}

.trailTable {
    background-color: white;
    width: 450px;
    display: flex;
    flex-flow: column;
    height: calc(100vh - 81px);
    overflow-y: auto;
    float: left;
    position: relative;
    padding-top: 20px;
}

.trailTable li {
    padding-left: 25px;
}

.trailTable input[type=search] {
    width: 400px;
    height: 35px;
    border-radius: 20px;
    /* border-right: 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; */
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    font: 13px Manrope;
}
.trailTable input[type=submit] {
    width: 100px;
    height: 35px;
    background-color: #2c5601;
    border-color: #2c5601;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    font: 13px Manrope;
    color: white;
}
.trailTable input[type=submit]:hover {
    background-color: #2b3820;
    border-color: #2b3820;
}

.trailTable .pictureframe {
    width: 400px;
    height: 250px;
    overflow: hidden;
    border-radius: 15px;
}

.prev, .next {
    cursor: pointer;
    position: absolute;
    margin-top: 26%;
    color: white;
    opacity: 50%;
    font-size: 18px;
    transition: 0.6s ease;
    user-select: none;
}

.prev:hover, .next:hover {
    opacity: 100%;
}

.prev {
    margin-left: 45px;
}

.next {
    margin-left: 350px;
}

.trailTable img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.trail {
    font: 17px Manrope;
    font-weight: 600;
    text-decoration: none;
    color: #2b3820;
}

.park {
    font: 13px Manrope;
    font-weight: 600;
    color: #656e5e;
}

.diff {
    font: 13px Manrope;
    color: #2b3820;
    padding-top: 10px;
}

.len {
    font: 13px Manrope;
    color: #656e5e;
}

ul {
    list-style-type: none;
}