.reviewButton {
  width: 400px;
  background-color: #2c5601;
}

.reviewButton:disabled {
  background-color: #c2c2b8;
  border: 1px solid #9f9f95;
}

.modal, .updateModal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
  }

  .modal-content {
    /* margin: 15% auto; */
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    width: 500px;
    height: 85vh;
    max-height: 550px;
  }

  .close {
    color: #2b3820;
  }

.stars {
    display: inline-block;
    width: 50px
  }

.modal-content textarea {
    height: 200px;
    width: 462px;
}

.modal-content button {
    width: 100px;
}

.modalName {
    font: 27px Manrope;
    font-weight: 600;
    color: #2b3820;
    padding-top: 25px;
}

.modalRating, .modalBody {
  font: 15px Manrope;
  color: #2b3820;
  margin-top: 20px;
  margin-bottom: 5px;
}

.modalBody {
  margin-bottom: 10px;
}

.modal-content textarea {
  font: 15px Manrope;
  color: #2b3820;
  border-radius: 10px;
  border: 1px solid #c2c2b8;
  padding-top: 14px;
  padding-left: 18px;
  padding-bottom: 14px;
  padding-right: 18px;
}