.deleteContainer {
    text-align: right;
    height: 1px;
}

.deleteButton {
    background-color: #ab251b;
    border-color: #ab251b;
    height: 25px;
    margin-left: 375px;
}

.topline {
    display: inline-flex;
}

.modal-content label {
    font: 50px Manrope;
}

.modal-content label:hover {
    color: #5de371;
}

.modal-content input {
    display: none;
}

.modal-content input:checked+label {
    display: inline-block;
    color: #5de371;
}