.trailShow {
    background-color: white;
    width: 450px;
    /* display: flex;
    flex-flow: column; */
    height: calc(100vh - 61px);
    overflow-y: auto;
    float: left;
    position: relative;
}

.indented {
    padding-left: 25px;
    padding-right: 25px;
}

.showpageImage {
    width: 450px;
    height: 275px;
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay {
    height: 200px;
    margin-top: -230px;
    display: flex;
}

.phototext {
    align-self: flex-end;
}

.trailD {
    font: 27px Manrope;
    font-weight: 600;
    text-decoration: none;
    color: white;
}

.parkD {
    font: 15px Manrope;
    font-weight: 600;
    color: white;
    display: block;
}

.diffD, .showRevAvg {
    font: 15px Manrope;
    color: white;
    line-height: 200%;
    display: inline-block;
}

.showStar {
    display: inline-block;
    font: 15px Manrope;
    color: #5de371;
}

.detailsD {
    margin-top: 25px;
}

.detailsD ul:first-child {
    font: 13px Manrope;
    color: #656e5e;
}

.detailsD ul:last-child {
    font: 16.5px Manrope;
    font-weight: 600;
    color: #2b3820;
}

.detailsD {
    height: 55px;
    padding-top: 20px;
}

.detailsD li {
    display: inline-flex;
    width: 128px;
}

.descriptionD {
    font: 16.5px Manrope;
    color: #2b3820;
    line-height: 175%;
}