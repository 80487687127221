.home {
    background-image: url('../../resources/Trailblazer.png');
    background-size: cover;
    /* background-position: 50% -3px; */
    width: 134px;
    line-height: 30px;
    margin-left: 25px;
    /* vertical-align: text-bottom; */
}

.exploreLink {
    background-size: cover;
    background-position: center;
    width: 134px;
    line-height: 36px;
    text-align: center;
}

.navButton {
    width: 75px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid #efefec;
    background-color: #efefec;
    color: #2b3820;
    font: 13px Manrope;
    font-weight: 600;
}
.navButton:hover {
    border: 1px solid #dbdad2;
    background-color: #dbdad2;
}

.navBackground {
    border-bottom: 1px solid #dbdad2;
}

.navTable {
    height: 60px;
    list-style-type: none;
    font: 13px Manrope;
    font-weight: 600;
    position: relative;
    top: 12px;
}

.navTable a {
    color: #2b3820;
    text-decoration: none;
}

.navTable .home {
    color: white;
}

.navTable li {
    display: inline-flex;
}

.navTable li:last-child {
    float: right;
    padding-right: 25px;
    padding-top: 2px;
}